<template>
  <v-app id="body">
    <v-main>
      <v-container class="fill-height" fluid>
        <v-row align="center" justify="center">
          <v-col cols="12" sm="8" md="4" align="center" justify="center">
            <v-card class="mx-auto">
              <v-overlay color="green_overlay" :value="overlay">
                <v-progress-circular
                  indeterminate
                  size="100"
                  width="7"
                  color="green_onOff"
                ></v-progress-circular>
              </v-overlay>
              <v-card-text>
                <v-img
                  src="@/assets/img/onoff.png"
                  height="200"
                  contain
                ></v-img>
                <v-form ref="form" v-model="valid" lazy-validation>
                  <v-text-field
                    prepend-inner-icon="mdi-gmail"
                    label="Correo Electronico"
                    required
                    clearable
                    color="green_onOff"
                    v-model="email"
                    :rules="emailRules"
                  ></v-text-field>
                  <v-text-field
                    prepend-inner-icon="mdi-lock"
                    name="input-10-1"
                    label="Contraseña"
                    required
                    counter
                    clearable
                    color="green_onOff"
                    :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="show1 ? 'text' : 'password'"
                    @click:append="show1 = !show1"
                    :rules="passwordRules"
                    v-model="password"
                  ></v-text-field>
                  <v-btn
                    class="mr-4 white--text"
                    block
                    color="green_onOff"
                    depressed
                    @click="login()"
                    :disabled="!valid"
                  >
                    Ingresar
                  </v-btn>
                </v-form>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-snackbar v-model="alert" timeout="2000" top :color="color" >
          <center>{{ text }}</center>
        </v-snackbar>
      </v-container>
    </v-main>
  </v-app>
</template>
<script>
import authenticationService from "@/Services/authenticationServices.js";
export default {
  name: "Login",
  data() {
    return {
      overlay: false,
      color: "",
      text: "",
      alert: false,
      show1: false,
      valid: true,
      email: "",
      password: "",
      passwordRules: [
        (v) => !!v || "Contraseña es requerida",
        (v) => v.length >= 6 || "Mínimo 6 caracteres",
      ],
      emailRules: [
        (v) => !!v || "Correo electronico es requerido",
        (v) => /.+@.+\..+/.test(v) || "El correo electronico debe ser válido",
      ],
      rules: {
        required: (value) => !!value || "Required.",
        min: (v) => v.length >= 8 || "Mínimo 6 caracteres",
        emailMatch: () =>
          `El correo electrónico y la contraseña que ingresaste no coinciden`,
      },
    };
  },
  methods: {
    login() {
      this.overlay = true;
      var data = {
        email: this.email,
        password: this.password,
      };
      authenticationService
        .login(data)
        .then((response) => {
          
          this.overlay = false;
          if (response.data.status) {            
            localStorage.setItem("token", response.data.data.api_token);
            localStorage.setItem("user_id", response.data.data.id);
            this.color = "success";
            this.text = "Bienvenido";
            this.alert = true;
            this.$router.push("/");
          } else {
            this.color = "red";
            this.text = response.data.message;
            this.alert = true;
          }
        })
        .catch(function (error) {
          this.overlay = false;
          this.color = "error";
          this.text = error;
          this.alert = true;
          console.error(error);
        });
    },
  },
};
</script>